import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Giveaways from '../../components/Page/Giveaways/Giveaways';
import SEO from '../../components/Seo/Seo';
import streamerPicture from './images/stomp.png';

const GiveawayPage = ({ pageContext: { intl } }) => {
  const lang = 'pl';
  const title = 'Stomp Giveaway!';
  const description =
    'Wypełnij parę prostych zadań i zwiększ swoją szansę na zdobycie stylowego skina na AWP';

  const hero = {
    title,
    description,
    glemUrl: 'https://gleam.io/zzUuH/stomp-x-skinwallet-giveaway',
    glemLoadingText: 'Wczytuję...',
    glemPlaceholderText: 'Stomp x Skinwallet Giveaway',
  };
  const streamer = {
    title: 'Złap AWPę od Stompa i Skinwalleta',
    description:
      'Pro-gracz, streamer i AWPer Izako Boars Daniel "Stomp" Płomiński łączy siły ze Skinwalletem by rozdać świeżutką AWPę. Oglądaj jego stream, wykonuj zadania i zbieraj punkty, by zyskać szansę na stylowy AWP | Neo-Noir.',
    image: (
      <LazyLoadImage
        src={streamerPicture}
        alt="Stomp"
        width={300}
        height={300}
      />
    ),
  };
  const marketURL =
    'https://www.skinwallet.com/market/?ref=stompstomp&utm_source=partner&utm_campaign=stompstomp&utm_medium=stompstomp&utm_content=giveaway';

  return (
    <>
      <SEO
        lang={lang}
        title={title}
        description={description}
        pathname={intl.originalPath}
        robots="noindex, follow"
      />
      <Giveaways
        lang={lang}
        hero={hero}
        streamer={streamer}
        marketURL={marketURL}
      />
    </>
  );
};

GiveawayPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default GiveawayPage;
